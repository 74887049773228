import React from 'react'

function Publish_Month({date}) {

const publishDate = new Date(date);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = publishDate.toLocaleDateString('en-US', options);

  return (
    <span>{formattedDate.split(",")[0]}th,{formattedDate.split(",")[1]} </span>
  )
}

export default Publish_Month