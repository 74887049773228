import React, { memo, useEffect } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import CaseStudeiesSimplified from "../components/CaseStudeiesSimplified"
import Publish_Month from "./Publish_Month"
const NewsDetails = ({ isAbout, career, data, dataforImages }) => {
  let webinarLocation
  let news
  if (typeof window !== "undefined") {
    webinarLocation = window.location.pathname.includes("/WebinarEvents")
    news = window.location.pathname.includes("/NEWS")
  }

  const fecthApiData = async url => {
    try {
      const res = await fetch(url)
      const data = await res.json()
    } catch (error) {
    }
  }

  useEffect(() => {
    const API =
      "https://newsapi.org/v2/top-headlines?sources=techcrunch&apiKey=c3ab41d7df654b888d301b25bac67221"
    fecthApiData(API)
  }, [])
  const loopItration = webinarLocation ? data?.events : data?.News
  const events =data?.events ? loopItration?.filter(event => event?.id !== 0) : loopItration;
  const annoucedEvents=events.filter(event=>event.state==="Announced")
  const ongoingEvents=events.filter(event=>event.state!=="Ended"&&event.state!=="Announced")
  const endedEvents=events.filter(event=>event.state==="Ended")
  const filteredEvents = annoucedEvents.concat(ongoingEvents,endedEvents);
  return (
    <div className="news-container">
      <div className="news-Heading-container">
        <div className="heading-postion ">
          <span className="news-heading">{data.title}</span>
        </div>
      </div>
      {webinarLocation ? (
        <CaseStudeiesSimplified
          TextData={{
            title: "",
            Data: [
              {
                peragraph: "State of the art integration Services for D365 and Odoo",
                list: [
                  "Building integrated custom applications.",
                  "Bulk internal and external distribution soluions which are seemlessly integrated with D365.",
                  "Integrated inventory and sales solution with Xstack exemplifies smooth omnichannel retailing experience.",
                ],
              },
              {
                peragraph: "Excel in solution building, maintenance, and promotion",
                list: [
                  "Building products which ensure availability to the users, covering multiple plateforms Web, Mobile (Apple/Android), IoT etc.",
                  "We aim to excel our services to support most product life cycle stages from development to promoting (digital marketing) to maintinance.",
                ],
              },
              {
                peragraph: "Achieve excellence in AI-powered solutions",
                list: [
                  "AI-Powered customer service chatbot for any industory.",
                  "AI-Powered Analysis and forcasting for suply chain solutions.",
                ],
              },
            ],
          }}
        />
      ) : (
        ""
      )}
      <div
        className={`new-card-container ${webinarLocation ? "webinar-with-padding" : ""
          }`}
      >
        {filteredEvents?.map((newsItem, index) => (
          <div key={index} className="news-card-width-resposoive">
            <div
              className={`new-card-size news-card${index + 1}`}
            >
              {newsItem?.attachment?.length ? <img style={{ height: '100%', width: '100%' }} src={'data:image/png;base64,' + newsItem?.attachment[0]?.attachment.substring(2, newsItem?.attachment[0]?.attachment.length - 1)} alt="News item"/> : <Img
                fluid={
                  dataforImages[`newsCard${index + 1}`]?.childImageSharp.fluid
                }
              />}

            </div>
            <h3>{newsItem.heading}</h3>
            
            {newsItem.location && (
              <p className="LocationPadding">
                <strong>Location:</strong> {newsItem.location}
              </p>
            )}
            {newsItem.date && (
              <p className="dataTimepadding">
                <strong>Date & Time:</strong> <Publish_Month  date={newsItem.date}/>{newsItem.date.split(" ")[1]}
              </p>
            )}
            {newsItem.travelBusiness && (
              <p className="LocationPadding">
                <strong>Travel, Business</strong> {newsItem.travelBusiness}
              </p>
            )}
            <p className="descriptionSet">{newsItem.description}</p>
            {!news ? ( <Link to={newsItem.id && newsItem.state === 'Announced' && `/WebinarEvents/Register?id=${newsItem.id}`
                } >
                  {webinarLocation ? (newsItem.state==="Announced"?<button className="register-button">Register Now</button>:<span className="status-data">Status:{newsItem.state}</span> ): newsItem.button}
                </Link>
              
            ) : (
              ""
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
export default memo(NewsDetails)
